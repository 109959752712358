@import 'colours.scss';

.batch-table {
  max-width: 100%;

  table {
    width: 100%;
  }
}

.box {
  border: 1.5px solid $aqua;

  padding: 1rem;
  border-radius: 5px;
  margin-top: .5rem;
}

.box-inside {
  padding: .75rem;
}

:global div.progress {
  display: inline-flex;
  min-width: 80%;
  // max-width: 90%;
  vertical-align: text-bottom;
  float: right;
}

.status {
  min-width: 4rem;
}

:global .btn.btn-download {
  background-color: $aqua;
  color: $white;

  &:hover {
    color: $white;
    background-color: $aquaAlt;
  }

  &[disabled] {
    background-color: $orange;
  }
}

:global .btn.btn-stop {
  background-color: $orange;
  color: $white;

  &:hover {
    color: $white;
    background-color: $orangeAlt;
  }
}

.align-header {
  float: right;
}

.batch-status {
  margin-left: 1rem;
  vertical-align: middle;
  color: $aqua;
}

button.btn-create-batch {
  background-color: $white;
  border-color: $aqua;
  border-width: 2px;
  color: $aqua;

  &:hover {
    background-color: $aqua;
    color: $white;
  }
}

span.default-scenario {
  color: $aqua;
  font-size: .65rem;
}

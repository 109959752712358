@import 'colours.scss';

@mixin border {
  border: 2px solid $aqua !important;
}

input, textarea {
  &:not(.base-value){ 
    @include border;
  }

  &:disabled, &[readonly] {
    background-color: inherit !important;
  }
}

select {
  @include border;
}

select, input {
  &.long {
    height: 3rem;
    font-size: 1.2rem;
  }
}

.run-count-suffix {
  margin-left: .5rem;
  font-size: .9rem;
}

.titleHeader {
  background-color: $aqua;
  padding: .5rem 0;
  color: $white;
}

button[type="button"] {
  color: $white;

  &:hover {
    color: $white;
  }

  &.btn-run {
    background-color: $aqua;
  }

  &.btn-save { 
    background-color: $aqua;
  }
}

.back-icon {
  margin-top: -2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;

  cursor: pointer;
}

@import 'colours.scss';

.box {
  border: 1.5px solid $aqua;

  padding: 1rem;
  border-radius: 5px;
  margin-top: .5rem;
  cursor: pointer;
}

.batch-status {
  margin-left: 1rem;
  vertical-align: middle;
  color: $aqua;
}

.btn.btn-options {
  color: black;
  margin-left: 1rem;

  &:hover {
    color: black;
  }
}

.dropdown-inline {
  display: inline;
}

$orange: #F16724;
$orangeAlt: #FF894E;

$aqua: #08BFDD;
$aquaAlt: #5CE3F9;

$very-light-grey: #F7F7F8;
$light-grey: #E3E4E5;
$darker-grey: #ACACAE;
$darkest-grey: #77777A;

$white: #FFFFFF;

$lime-green: #77BC1F;

.iconPrimary {
  color: $aqua;
}

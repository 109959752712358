@import '~bootstrap-css-only/css/bootstrap.min.css';
@import '~font-awesome-animation/dist/font-awesome-animation.min.css';
@import 'colours.scss';

body {
  margin: 0;
  padding-bottom: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.progress-bar {
  background-color: $aqua;

  &.failed {
    background-color: $orange;
  }

  &.running {
    animation: pulse 3s infinite;
  }

  &.completed {
    background-color: $lime-green;
  }
}

@keyframes pulse {
  0% {
    background-color: $aqua;
  }
  
  50% {
    background-color: $aquaAlt;
  }

  100% {
    background-color: $aqua;
  }
}

button.btn:disabled {
  pointer-events: none;
}

// remove caret on dropdowns
.dropdown-toggle:after {
  content: none;
}